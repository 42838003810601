html, body { height: 100%; scroll-behavior: smooth; }

body { margin: 0 auto; position: fixed; background: rgb(252, 252, 252); width: 100%; font-family: Roboto, "Helvetica Neue", sans-serif;}

.care-link {
  text-decoration: none;
  color: #2d7ff9;
  cursor: pointer;
}

.care-link:hover {
  text-decoration: underline;
}

.invisible-tabs > .mat-tab-header {
  display: none;
}

a.no-decoration, a.no-decoration:visited, a.no-decoration:active {
  text-decoration: none;
  color: inherit;
}

a.no-decoration:hover {
  text-decoration: underline;
}

.container-sb .mat-tab-label {
  min-width: 100px;
  padding: 0 5px;
}

input, textarea {
    padding: 7px 11px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    box-shadow: none;
}

input {
  box-sizing: border-box;
}
    

input:focus, textarea:focus, select:focus {
  outline-color: rgba(45,127,249, 0.5);
}

/* Style for custom quill buttons */
.ql-comment:after {
  border: 1px solid #1c9e9b;
  background-color: rgba(0,0,0, 0.15);
  padding: 4px;
  border-radius: 4px;
  content: "Comment";
  color: #000;
  position: absolute;
  right: 10px;
  top: 8px;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border-radius: 12px;
  margin: 2px 0 10px;
  background-color: rgba(0, 0, 0, 0.05);
}

.ql-editor {
  padding: 0px 0px;
}

.ql-container p {
  font-size: 16px;
  line-height: 24px;
}

/* Prevent user select where necessary */
.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}